import React, {Component} from 'react';
import contact_icon from './images/contact_icon.png';
import events_icon from './images/events_icon.png';
import home_icon from './images/home_icon.png';

class MenuBlock extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            active: false
        }

        this.handleClick = this.handleClick.bind(this)
        this.handleMouseEnter = this.handleMouseEnter.bind(this)
        this.handleMouseLeave = this.handleMouseLeave.bind(this)
    }

    handleClick() {
        console.log('clicked on ', this.props.name)
        document.getElementById(this.props.name).scrollIntoView({ behavior: 'smooth' });
    }

    handleMouseEnter() {
        this.setState({
            active: true
        })
    }
 
    handleMouseLeave() {
        this.setState({
            active: false
        })
    }


    render() {
        return (
            <div 
                className={`menu-block${this.state.active ? '--active' : '--inactive'}`} 
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onClick={this.handleClick}
            >
                <img 
                    src={this.props.image}
                    alt={this.props.alt}
                    className='menu-block__icon'
                />
                {
                    this.state.active &&
                    <h2>{this.props.name}</h2>
                }
            </div>
        )
    }
}

class Menu extends React.Component {
    constructor(props) {
        super(props)
        this.menuBlocks = [
            {name: 'Home', src: home_icon}, 
            {name: 'Events', src: events_icon}, 
            {name: 'Contact', src: contact_icon}
        ]
    }

    render() {
        const blocks = this.menuBlocks.map((block, index) => {
            return <MenuBlock 
                name={block.name}
                alt={block.name + ' menu icon'} 
                image={block.src} 
                key={block.name+index}/>
        })
        return (
            <div className='menu'>
                {blocks}
            </div>
        )
    }
}

export default Menu