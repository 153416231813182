import React from 'react';
import Menu from './Menu.js';
import './App.scss';
import logo from './images/ICC_logo.png';
import banner from './images/choirbanner.png';
import insta from './images/insta_icon.png';
// import rehearsal from './images/bandstand-rehearsal.mp4'
import springConcert from './images/sing-in-the-spring.jpeg';
import bigBandGig from './images/big-band-gig.png'

function App() {
  return (
    <div className='main'>
      <Menu />
      <div className='content'>
        <img
          className='header-logo'
          src={logo}
          alt='Islington Community Choir logo'
        />
        <div className='section'>
          <h1 id='Home'>About us</h1>
          <p>
            The Islington Community Choir meets every Sunday 11:30am - 13:30pm to sing,
            stretch, move, laugh, drink tea, chat, make silly noises, and make
            amazing noises too. We are a non-auditioned group that accepts
            EVERYONE…. Yes, even you “I only sing in the shower” types. We sing
            music from all over the world and from any style, learning a lot
            about other cultures and singing traditions as we go.
            <br />
            <br />
            In the ICC, community is the most important part of what we do, and
            we often go for lunch together, book tickets to shows and concerts
            and have karaoke sessions! It’s the best place to be to find people
            with positivity, great humour and a love of music.
            <br />
            <br />
            Come join us in:
            <br />
            New Unity
            <br />
            227a Upper Street
            <br />
            N1 2TZ
            <br />
            You won’t regret it!
          </p>

          <h1 id='Events'>Events</h1>
          <p>
            Sunday sessions are back on come rain or shine - drop us a line to
            find out what we're singing this week! You can also check out the
            good times from our past events on our instagram page:
            <br />
            <a href='https://www.instagram.com/islingtoncommunitychoir/'>
              <img className='insta-link' src={insta} alt='insta' />
            </a>
            <br />
          </p>
          <p>
          We have an unmissable concert lined up in collaboration with the
          <a href='https://www.facebook.com/groups/246339478848913/'>
          London Collective Big Band</a>
          with tight grooves, crunchy harmonies, and massive crowd favourites! Get your tickets
          <a href='https://www.ticketsource.co.uk/londoncollectivebigband/brass-beats-and-belting-voices/e-rkprex'>
           ~ h e r e ~</a>
          </p>
          <img
          //  width='30%'
            className='big-band-gig'
            src={bigBandGig}
            alt='Big band gig'
          /> 
          <p>
            We're also proud to display our last concert in collaboration with
            the Bellow Fellows! The performance was a rousing mix of traditional and modern tunes
            from around the world, with proceeds going to the Islington Centre
            for Refugees & Immigrants.
          </p>
          <img
          //  width='30%'
            className='sing-in-the-spring'
            src={springConcert}
            alt='Spring Concert'
          />
          <h1 id='Contact'>Contact us</h1>
          <img className='print-banner' src={banner} alt='ICC Banner' />
          <p>
            Here's some of our old branding for those who may recognise us.
            <br />
            <br />
            If you have any other questions, just message us at
            islingtonvocalcollective@gmail.com or on our{' '}
            <a href='https://www.instagram.com/islingtoncommunitychoir/'>
              instagram
            </a>{' '}
            and we'll be in touch!
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;

// We're also proud to display our last concert in collaboration with the Bellow Fellows! The performance was a rousing mix of folk tunes from around the world, with proceeds going to the Islington Centre for Refugees & Immigrants.
// </p>
// <img
//     className='sing-in-the-spring'
//     src={springConcert}
//     alt='Spring Concert'
// />
